<template>
  <div><div class="title">配送服务</div><div style="text-align: center; line-height: 20px; margin-top: 10px;">
    发布时间：2022-01-07 17:55:07
  </div><div data-v-0ab10f90="" style="font-size: 14px;"><p>&nbsp;</p>
    <p><strong>物流包装</strong></p>
    <p>{{ $tp().OPEN_COM_NAME }}与多家快递公司合作配送商品，顾客在{{ $tp().OPEN_COM_NAME }}成功购买商品的每一个订单（限一个邮寄地址），系统都会默认生成一个包裹。{{ $tp().OPEN_COM_NAME }}目前暂时不提供自选物流的服务， 我们会根据订单中的收货地址和商品种类选择最合适的物流公司为顾客配送。</p>
    <p><strong>配送方式</strong></p>
    <p>{{ $tp().OPEN_COM_NAME }}与多家快递公司合作配送商品，顾客在{{ $tp().OPEN_COM_NAME }}成功购买商品的每一个订单（限一个邮寄地址），系统都会默认生成一个包裹。{{ $tp().OPEN_COM_NAME }}目前暂时不提供自选物流的服务，我们会根据订单中的收货地址和商品种类选择最合适的物流公司为顾客配送。
      由保税区直接发货的商品，顾客在网站下单，下单后工作人员根据订单信息向海关系统进行申报，申报成功，接到装载单，进行货物的打包，安排快递将包裹派送至顾客指定地址。全程海关监控。目前保税仓直发默认EMS</p>
    <p><strong>配送范围</strong></p>
    <p>{{ $tp().OPEN_COM_NAME }}配送范围覆盖全国大部分地区（港澳台地区除外）。</p>
    <p><strong>配送费用</strong></p>
    <p>{{ $tp().OPEN_COM_NAME }}全国（港澳台及偏远地区除外）包邮包税</p>
    <p><strong>配送时效</strong></p>
    <p>{{ $tp().OPEN_COM_NAME }}承诺，顾客之保税区订单由发货日起3-5个工作日内送达至收货地址。如超出15个工作日仍未送达，请联系客服进行核实，详情请参阅《退货政策》。 请注意以下情况均不在送达承诺服务范围内，包括但不仅限于：①如遇交通管制、大雨雪、洪涝、冰灾、地震、火灾、政府行为、暴力事件、游行、 罢工、节假日、停电等{{ $tp().OPEN_COM_NAME }}无法预知、克服、避免的情形；②因客户提供的收货信息影响时效（身份证姓名、身份证号码、收件地址或电话等，出现错误 、重复、同一身份证使用次数超过海关限制次数、信息缺失等）；③他人代收也等同于本人签收。</p>
    <p><strong>发货时间</strong></p>
    <p>顾客下单成功后，相关专业人员对顾客所订购的商品、邮寄地址、款项支付等信息进行审核。审核通过后，在1-3个工作日内将包裹发出；包裹发出 后，系统将配送相关信息更新至顾客的帐户信息中，顾客可通过主页的“用户中心－我的订单”查询包裹的派送情况。</p>
    <p>注：部分联营商家直发商品发货时间需以商品详情页为准，敬请谅解！</p>
    <p><strong>配送追踪</strong></p>
    <p>可以登录：订单中心-发货订单，找到相应的订单编号，查看订单详情，在订单状态中查询到订单的快递单号及配送情况。</p></div></div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/11/15 10:38
 * @version 1.0
 * @description
 */
export default {
  name: 'DeliveryServices'
}
</script>

<style scoped>

</style>
